html,
body {
	margin: 0;
	padding: 0;
	height: 100%;
	background-color: #eef4f7;
}

#root,
.App {
	height: 100%;
	background-color: #eef4f7;
}

input:-webkit-autofill {
	box-shadow: none;
	-webkit-box-shadow: 0 0 0 50px white inset;
	-webkit-text-fill-color: #333;
}

@keyframes rotation {
	from {
		transform: rotate(0deg);
	}
	to {
		transform: rotate(359deg);
	}
}
